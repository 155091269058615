import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshopNoTab,
  InternalInstructor,
  InternalTestimonial
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './leitura-do-campo-energetico-emocional.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Leitura do Campo Energético Emocional' />
      <InternalHeader className={styles.header} withType>
        <small>Online</small>
        <h1>
          Leitura do Campo
          <br />
          <strong>Energético Emocional</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Curso</strong>
            </h2>
            <p>
              Um curso prático e vivencial unindo os Florais de Bach e a Radiestesia. Com o uso do pêndulo fazemos um
              mapa das emoções em desequilíbrio e com os Florais de Bach oportunizamos o desenvolvimento das virtudes
              necessárias para o equilíbrio e bem-estar emocional. Você aprenderá, de forma rápida e segura, a utilizar
              um instrumento radiestésico aliado aos Florais de Bach para complementar a consulta terapêutica.
            </p>
            <p>
              O Curso é ideal para terapeutas e todos os profissionais que desejam ter uma abordagem terapêutica a
              respeito do uso da Radiestesia e dos Florais de Bach no seu trabalho. O curso também pode ser feito por
              pessoas de outras áreas de atuação que desejam encontrar um novo olhar para sua prática profissional.
            </p>
            <h2>Objetivo</h2>
            <p>
              O objetivo é preparar o profissional com uma abordagem tanto para consulta presencial ou a distância. Não
              é necessário ter conhecimento prévio sobre radiestesia ou o uso do pêndulo.
            </p>
            <h2>
              O que você <strong>vai aprender</strong>
            </h2>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Introdução à Radiestesia
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Programação para uso do pêndulo
              </dt>
              <dd>fatores que dificultam e impedem o trabalho</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Como formular corretamente a pergunta
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Criação, uso e conservação de testemunhos
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Leitura do campo energético e a fórmula floral
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Gráficos e pranchas radiestésicas
              </dt>
              <dd>para consulta presencial e a distância</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />A consulta à Distância
              </dt>
              <dd>como estruturar e desenvolver uma consulta à distância</dd>
            </dl>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshopNoTab>
              <h3>
                <small>Curso à Distância</small>
                Leitura do Campo Energético Emocional
              </h3>
              <ul>
                <li>Aulas online agendadas ou gravadas</li>
                <li>Material complementar incluso</li>
                <li>Certificado de conclusão</li>
                <li>Sem pré-requisitos</li>
                <li>Curso destinado a todos os públicos </li>
                <li>Acesso por 12 meses a plataforma de ensino</li>
              </ul>
              <a
                href={WHATSAPPLINK}
                target='_blank'
                rel='noopener noreferrer'
                className={`button button--purpleReverse button internalWorkshop-button`}
              >
                Inscrição Curso Online <small></small>
              </a>
            </InternalWorkshopNoTab>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
